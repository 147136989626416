import React from 'react';
import MembruEchipa from './MembruEchipa';
import Grid from '@material-ui/core/grid';
import {AppBar, Typography, Container} from "@material-ui/core";

const andreea = "https://itfest.osut.ro/media/team/andreea1.jpg";
const placinta = "placinta.png";
const groza = "groza.png";
const tudorache = "tudorache.png";
const micula = "Daniel.png"
const ciucioiu = "ciucioiu.png";
const matei = "matei.png";
const gomoi = "gomoi.png";
const gabi = "gabi.png";
const pirvan = "pirvan.png"

const Echipa = () => {
    return (
        <div id="echipa">
            <Grid
                container
                spacing={12}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                alignContent="center">
                <Grid item md={12}>
                    <Typography
                        color="textPrimary"
                        variant="h3"
                        component="h1"
                        style={{
                        marginTop: 65,
                        marginBottom:30
                    }}>
                        Echipa
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={10}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                alignContent="center">
                <MembruEchipa echipa
                    titlu="Daniel Micula"
                    descriere="Coordonator General"
                    mail="daniel.micula02@e-uvt.ro"
                    poza={micula}
                    //telefon="0787-570-584"
                    />
              
                <MembruEchipa echipa
                    titlu="Ana Groza"
                    descriere="Responsabil Human Resources"
                    mail="ana.groza02@e-uvt.ro"
                    poza={groza}
                    />
                <MembruEchipa echipa
                    titlu="Alexandra Gomoi"
                    descriere="Responsabil Public Relations"
                    mail="alexandra.gomoi01@e-uvt.ro"
                    poza={gomoi}
                    />
                    <MembruEchipa echipa
                    titlu="Gabriela Anghelina"
                    descriere="Responsabil Imagine"
                    mail="elena.anghelina01@e-uvt.ro"
                    poza={gabi}
                    />
                    <MembruEchipa echipa
                    titlu="Ștefan Plăcintă"
                    descriere="Responsabil IT și Logistică"
                    mail="stefan.placinta01@e-uvt.ro"
                    poza={placinta}
                    />
                <MembruEchipa echipa
                    titlu="Roxana Ciucioiu"
                    descriere="Responsabil  Concursuri, Conferințe și Workshopuri"
                    mail="roxana.ciucioiu01@e-uvt.ro"
                    poza={ciucioiu}
                    />
                <MembruEchipa echipa
                    titlu="Andreea Tudorache"
                    descriere="Responsabil ICONical"
                    mail="andreea.tudorache98@e-uvt.ro"
                    poza={tudorache}
                    />
                <MembruEchipa echipa
                    titlu="Andreea Pîrvan"
                    descriere="Responsabil LAN Party"
                    mail="andreea.pirvan02@e-uvt.ro"
                    poza={pirvan}
                    />
                <MembruEchipa echipa
                    titlu="Roxana Matei"
                    descriere="Vicepreședinte Organizare Internă și Proiecte OSUT"
                    mail="roxana@osut.ro"
                    poza={matei}
                    />
                <MembruEchipa echipa
                    titlu="Andreea Argeșeanu"
                    descriere="Președinte OSUT"
                    mail="andreea.argeseanu@osut.ro"
                    poza={andreea}
                   // telefon="0746-036-096"
                    />

            </Grid>
        </div>
    );
}
export default Echipa;